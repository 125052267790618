import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";
import CmsImage from "../utilities/cms-image";
import { over } from "lodash";

const CenteredTitleWithBackgroundImage = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`section section-centered-title-with-image-bg ${
        data.noClippedCorner ? "" : "clipped-small-bottom"
      } ${data.addOverlay ? "overlay" : ""} ${
        data.constrainHeight ? "constrain-height" : ""
      }`}
      // style={
      //   data.image && data.image.localFile
      //     ? {
      //         background: `linear-gradient(180deg,rgba(10, 46, 66, 0.6) 0%, rgba(0, 169, 80, 0.6) 100%), url(${data.image.localFile.publicURL}) no-repeat center center / cover`,
      //       }
      //     : {}
      // }
    >
      <CmsImage className="img-zoom section-img" image={data.image} />
      <div className="container sz">
        <div className="wrapper">
          <Content className="content-center-title">{data.content}</Content>
          {data.link && (
            <CmsLink
              className="btn btn-shadow btn-arrow-long"
              link={data.link}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment CenteredTitleWithBackgroundImage on WpPage_Sections_Sections_CenteredTitleWithBackgroundImage {
    fieldGroupName
    name
    content
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
    link {
      target
      title
      url
    }
    noClippedCorner
    addOverlay
    constrainHeight
  }
`;

export default CenteredTitleWithBackgroundImage;
